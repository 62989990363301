<template>
  <div>
    <section class="section hero is-fullheight is-error-section">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-two-fifths">
              <div class="card has-card-header-background">
                <header class="card-header">
                  <p class="card-header-title">
                    <span class="icon"><i class="mdi mdi-lock default"></i></span>
                    <span>{{ $getTranslation('auth.forgot.title') }}</span>
                  </p>
                </header>
                <div class="card-content">
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                    <form ref="form" method="POST" action="forgot-password" @submit.prevent="handleSubmit" @keydown="clearError">

                      <InputWithValidation
                        horizontal
                        rules="required|email"
                        type="email"
                        v-model="email"
                        icon="email"
                        :label="$getTranslation('auth.form.email.label')"
                        :placeholder="$getTranslation('auth.form.email.placeholder')"
                      />

                      <hr>
                      <div class="field is-grouped">
                        <div class="control">
                          <b-button
                            native-type="submit"
                            type="is-black"
                            @click="passes(submit)"
                            >{{ $getTranslation('form.submit') }}</b-button
                          >
                        </div>
                        <div class="control">
                          <b-button type="is-black is-outlined" @click="reset"
                            >{{ $getTranslation('form.reset') }}</b-button
                          >
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
              <div class="control">
                <p>{{ $getTranslation('auth.forgot.login') }}
                  <a href="/login" class="is-outlined is-black" v-text="$getTranslation('auth.signin.button')"></a>
                </p>

                <p>{{ $getTranslation('auth.forgot.register') }}
                  <a href="/register" class="is-outlined is-black" v-text="$getTranslation('auth.signup.button')"></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { loggedInPage } from '@/api-routes.js'
import { mapGetters, mapActions } from 'vuex'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-forgot-password',
  mixins: [FormTemplateMixin],
  data () {
    return {
      email: ''
    }
  },
  computed: {
    //
  },

  methods: {
    ...mapActions([
      'forgotPassword',
      'clearError'
    ]),

    async submit () {
      if (!this.error) {
        let forgot = {
          email: this.email
        };


        this.forgotPassword(forgot)
          .then(() => {
            setTimeout(() => {
              if(this.error) {
                this.$buefy.snackbar.open({
                  message:  this.error,
                  type: 'is-danger',
                  position: 'is-top',
                })
              } else {
                this.$buefy.snackbar.open({
                  message:  this.$getTranslation('auth.forgot.message'),
                  duration: 5000,
                  position: 'is-top',
                  indefinite: true,
                  onAction: () => {
                    this.$router.push('/');
                  }
                })
              }
            }, 500)
          })
          .catch(() => {
            this.$buefy.snackbar.open({
              message:  'Something went wrong',
              type: 'is-danger',
              position: 'is-top',
            })
          })

      }
    }
  }
}
</script>
